import { About, Schedule } from "components";
import { Helmet } from "react-helmet";

const Home = ({ scrollRef }) => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>
					CRPs - Desafíos de la Salud Mental en Pandemia. Innovando en Telesalud
				</title>
				<link rel="canonical" href="https://crps.cl/"/>
				<meta
					name="description"
					content="Debido al Covid-19, quienes trabajamos en el área de la salud
                mental, hemos visto la necesidad de mudar gran parte de nuestras
                acciones al entorno virtual, que es donde actualmente ocurren
                las interacciones sociales que pueden brindar acompañamiento y
                contención."
				/>
			</Helmet>
			<Schedule scrollRef={scrollRef}/>
			<About/>
			{/*<CountdownTimer />*/}
		</div>
	);
};

export default Home;
